export interface RFRatingsItemModel {

    key: RFRatingsItemKey;
    title: string;
    question: string;
}

export enum RFRatingsItemKey {
    Recommend= "recommend",
    Budget = "budget",
    Attitude = "attitude",
    Punctuality = "punctuality",
    Communication = "communication",
}

