import React from "react";
import { nanoid } from "nanoid";
import { Spinner } from "react-bootstrap";
import styles from "./LoadingSpinners.module.scss";

export function LoadingSpinners() {

    const spinners = Array(3)
        .fill(null)
        .map(() => <Spinner
            className={styles.Spinner}
            animation="grow"
            variant="primary"
            size="sm"
            key={nanoid()}
        />);

    return <div>
        {spinners}
    </div>;
}
