import moment from "moment";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useForm } from "react-hook-form";
import { QuestionCategory } from "shared/models/Service";
import { Dictionary } from "shared/models/Dictionary";
import { SFContactDetailsApi } from "../contact-details/SFContactDetailsApi";
import { SFQuestion } from "../question/SFQuestion";
import { SFQuestionsProps } from "./SFQuestionsProps";
import styles from "./SFQuestions.module.scss";

const SFQuestions = forwardRef((props: SFQuestionsProps, ref) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
    } = useForm({
        defaultValues: getDefaultValues(),
        mode: "onChange"
    });

    useImperativeHandle(
        ref,
        () => ({
            validate: () => {
                triggerValidation();
                return isValid;
            }
        } as SFContactDetailsApi),
    );

    const onSubmit = (data: Dictionary<string>) => {
        props.questions.forEach(question => {
            if (question.category === QuestionCategory.datetime) {
                let date = data[question.id as string]
                question.response = formatDateForServer(date)
            } else {
                question.response = data[question.id as string];
            }
        });
    };
    
    const questions = props.questions.map(question => <SFQuestion
            key={question.id}
            errors={errors}
            register={register}
            question={question}
            setValue={setValue}
            getValues={getValues}
    />);

    const submitButtonRef = useRef<HTMLButtonElement>(null);
    return <div className={styles.MainContainer}>
        {
            questions &&
            <form
                className={styles.MainWrap}
                onSubmit={handleSubmit(onSubmit)}
            >
                {questions}

                <button
                    className={styles.Hidden}
                    type="submit"
                    ref={submitButtonRef}
                >
                    Submit
                </button>
            </form>
        }
        </div>;

    // region Local functions

    function triggerValidation(): void {
        submitButtonRef?.current?.click();
    }

    function formatDateForServer(date?: string): Date | null {
        if (date) { 
            return moment(date).toDate()
        }
        return null
    }

    // 
    function getDefaultValues(): Dictionary {
        const dict: Dictionary = {};
        const multiChoiceQuestions = props.questions.filter(q => q.category === QuestionCategory.multipleChoice);
        multiChoiceQuestions.forEach(q => dict[q.id as string] = []);
        return dict;
    }

    // endregion
});

SFQuestions.displayName = "ServiceFormQuestions";
export default SFQuestions;
