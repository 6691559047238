import { nanoid } from "nanoid";
export interface Service {
    id: string
    userId: string
    title: string
    description: string
    questions: Question[]
    imagePath: string | null
}

export interface Question {
    id: string
    prompt: string
    category: QuestionCategory
    options: string[]
    isRequired: boolean
    response: any | null
}

export enum QuestionCategory {
    text = "TEXT",
    paragraph = "PARAGRAPH",
    datetime = "DATETIME",
    singleChoice = "SINGLE_CHOICE",
    multipleChoice = "MULTIPLE_CHOICE",
}

export const defaultService: Service = {
    id: "",
    userId: "",
    title: "General Inquiry",
    description: "Please fill out the form below.",
    imagePath: null,
    questions: [
        { 
            id: nanoid(),
            prompt: 'Please describe your issue in detail.',
            isRequired: true,
            category: QuestionCategory.paragraph,
            options: [],
            response: null
        }
    ]
}
