import firebase from "firebase/compat";
import 'firebase/storage'

export class NetworkHelper { 
    public static downloadURLFromID(id: string): Promise<string> { 
        const path = `images/${id}.jpeg`
        const ref = firebase.storage().ref().child(path)
        const url = ref.getDownloadURL()
        return url
    }

    public static downloadURLFromPath(path: string): Promise<string> { 
        const ref = firebase.storage().ref().child(path)
        const url = ref.getDownloadURL()
        return url
    }
}