import React from "react";
import { UIGallery } from "../gallery/UIGallery";
import { UILocation } from "../location/UILocation";
import { UIQuickFacts } from "../quick-facts/UIQuickFacts";
import { UIServices } from "../services/UIServices";
import { UIVerifiedBusiness } from "../verified-business/UIVerifiedBusiness";
import { UICompanyProps } from "./UICompanyProps";
import styles from "./UICompany.module.scss";
import { allUserVerifications } from "shared/models/User";

export function UICompany(props: UICompanyProps) {
    const user = props.user;
    

    return <div className={styles.CompanyWrap}>
        {
            !!user.company.name &&
                <div className={styles.Name}>
                    {user.company.name}
                </div>
        }

        {
            !!user.company.about &&
                <div className={styles.About}>
                    {user.company.about}
                </div>
        }

        {
            !!user.stats.received &&
                <div className={styles.HiredStats}>
                    Hired
                    <span className={styles.Underlined}>
                        {user.stats.received} time(s)
                    </span>
                    through Orbiter!
                </div>
        }

        {
            isVerified() &&
                <div className={styles.VerifiedBusiness}>
                    <UIVerifiedBusiness/>
                </div>
        }

        {
            !!user.location.city && !!user.location.state &&
                <div className={styles.Location}>
                    <UILocation
                        state={user.location.state}
                        city={user.location.city}
                    />
                </div>
        }

        {
            hasGallery() &&
                <div className={styles.Gallery}>
                    <UIGallery
                        galleryPaths={[...user.imagePaths.gallery!]}
                    />
                </div>
        }

        {
            hasServices() &&
                <div className={styles.Services}>
                    <UIServices
                        services={props.services}
                    />
                </div>
        }

        {
            hasQuickFacts() &&
                <div className={styles.QuickFacts}>
                    <UIQuickFacts
                        paymentMethods={user.company.paymentMethods}
                        employeeCount={user.company.employeeCount}
                        yearFounded={user.company.yearFounded}
                    />
                </div>
        }
    </div>;

    function hasServices(): boolean { 
        return user.company.services.length > 0
    }

    function hasQuickFacts(): boolean {
        return (
            user.company.yearFounded !== '' ||
            user.company.employeeCount !== '' ||
            user.company.paymentMethods.length > 0
        )
    }

    function isVerified(): boolean { 
        return allUserVerifications.every(v => {
            user.verifications.includes(v)
        })
    }

    function hasGallery(): boolean { 
        if (user.imagePaths.gallery) { 
            return user.imagePaths.gallery.length > 0
        }
        return false
    }
}
