import React from "react";
import styles from "./UIQuickFacts.module.scss";
import checkmark from "../../../assets/checkmark.png";
import { UIQuickFactsProps } from "./UIQuickFactsProps";

export function UIQuickFacts(props: UIQuickFactsProps) {

    const paymentMethods = props.paymentMethods.join(", ");

    return <div className={styles.MainWrap}>
        <div className={styles.Title}>
            Quick Facts
        </div>

        {
            !!props.yearFounded &&
                <div className={styles.Fact}>
                    {checkmarkIcon()}
                    <div className={styles.Text}>
                        Founded in {props.yearFounded}
                    </div>
                </div>
        }

        {
            !!props.employeeCount &&
                <div className={styles.Fact}>
                    {checkmarkIcon()}
                    <div className={styles.Text}>
                        {props.employeeCount} Employees
                    </div>
                </div>
        }

        {
            !!paymentMethods.length &&
                <div className={styles.Fact}>
                    {checkmarkIcon()}

                    <div className={styles.Text}>
                        <div className={styles.Underlined}>
                            Payment Methods:
                        </div>
                        {paymentMethods}
                    </div>
                </div>
        }
    </div>;

    function checkmarkIcon() { 
        return <div className={styles.Icon}>
            <img
                src={checkmark}
                alt={"Check"}
            />
        </div>
    }
}
