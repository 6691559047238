import { Nullable } from "./misc/types";
import { Service } from "./Service";

export interface User {
    id: string
    firstName: string
    lastName: string
    company: UserCompany
    location: UserLocation
    stats: UserStats
    ratings: UserRatings
    verifications: UserVerification[]
    imagePaths: UserImagePaths
}

export interface UserCompany { 
    name: string
    title: string
    secondaryTitle: string
    motto: string
    about: string
    yearFounded: string
    employeeCount: string
    paymentMethods: string[]
    services: string[]
    sectors: string[]
}

export interface UserStats {
    sent: number
    received: number
    assists: number
    closed: number
    reviews: number
}

export interface UserLocation { 
    city: string
    state: string
    zipCode: string
}

export interface UserRatings { 
    overall: Nullable<number>
    recommend: Nullable<number>
    budget: Nullable<number>
    attitude: Nullable<number>
    punctual: Nullable<number>
    communication: Nullable<number>
}

export interface UserImagePaths { 
    personal: string
    company?: string
    gallery?: string[]
}

export enum UserVerification { 
    email = "EMAIL",
    phoneNumber = 'PHONE_NUMBER'
}
export const allUserVerifications = [UserVerification.email, UserVerification.phoneNumber]