import firebase from "firebase/compat/app"
import { getAnalytics } from 'firebase/analytics'
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Config } from "./shared/helpers/Config";

const app = firebase.initializeApp(Config.firebaseConfigV3);
getAnalytics(app)

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
