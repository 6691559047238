import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import toastr from "toastr";
import PageLoader from "../shared/page-loader/PageLoader";
import { LocalStorageHelper as Storage } from "../../shared/helpers/LocalStorageHelper";
import { ContactDetails } from "../../shared/models/ContactDetails";
import { PoweredBy } from "../shared/powered-by/PoweredBy";
import { SFContactDetailsApi } from "./contact-details/SFContactDetailsApi";
import SFContactDetails from "./contact-details/SFContactDetails";
import { SFGeneral } from "./general/SFGeneral";
import { SFQuestionsApi } from "./questions/SFQuestionsApi";
import SFQuestions from "./questions/SFQuestions";
import styles from "./ServiceForm.module.scss";
import { ServiceFormProps } from "./ServiceFormProps";
import { Submission } from "shared/models/Submission";
import { SubmissionFactory } from "shared/models/factories/Submission.factory";
import { ContactDetailsFactory } from "shared/models/factories/ContactDetailsFactory";
import { UserServicesPostData } from "shared/models/post+payloads/UserServicesPostData";
import { ContactDetailsMapping } from "shared/models/mappings/ContactDetailsMapping";
import { AxiosNetworker } from "shared/networking/AxiosNetworker";

export function ServiceForm(props: ServiceFormProps) {
    const history = useHistory();

    const [imageLoaded, setImageLoaded] = useState<boolean>();
    const [submission, setSubmission] = useState<Submission>();
    const [contactDetails, setContactDetails] = useState<ContactDetails>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const submission = SubmissionFactory.create(props.service, props.referralId as string)
        const contactDetails = ContactDetailsFactory.create(props.referralId as string)
        setSubmission(submission);
        setContactDetails(contactDetails);
    }, [props.service]);

    const contactDetailsForm = useRef<SFContactDetailsApi>();
    const serviceQuestionsForm = useRef<SFQuestionsApi>();

    return <div className={styles.MainContainer}>

        <div className={styles.MainWrap}>
            <SFGeneral
                service={props.service}
                back={() => props.back()}
                imageLoaded={() => setImageLoaded(true)}
            />

            {
                submission && <SFQuestions
                    ref={serviceQuestionsForm}
                    questions={submission.responses}
                />
            }

            {
                submission && contactDetails &&
                    <SFContactDetails
                        ref={contactDetailsForm}
                        submission={submission}
                        contactDetails={contactDetails}
                    />
            }

            <Button
                className={styles.Submit}
                title={"Submit"}
                onClick={() => onSubmit()}
            >
                Submit
            </Button>

            {disclaimer()}

            <PoweredBy />
        </div>
        
        {pageLoader()}
        {/* {
            ((!!props.service.imageId && !imageLoaded) || loading) &&
                <PageLoader/>
        } */}
    </div>

    /* ------------------------------- Components ------------------------------- */

    function disclaimer() { 
        return <div className={styles.Disclaimer}>
            <div className={styles.DisclaimerTitle}>
                We do not sell your data to third-parties.
            </div>

            By submitting this form you agree to our

            <Link
                to="/terms-and-conditions"
                className={styles.Underlined}
                target="_blank"
            >
                terms and conditions
            </Link>.
        </div>
    }

    function pageLoader() { 
        if (
            (!!props.service.imagePath && !imageLoaded) || loading) { 
            return <PageLoader />
        }
    }

    /* ----------------------------- Local functions ---------------------------- */

    function onSubmit(): void {
        const contactDetailsValid = contactDetailsForm?.current?.validate();
        const serviceResponsesValid = serviceQuestionsForm?.current?.validate();

        if (contactDetailsValid && serviceResponsesValid) {
            setTimeout( () => submitRequest());
        }
    }

    async function submitRequest(): Promise<void> {
        setLoading(true);
        try {
            const contactDetailsDto = ContactDetailsMapping.prepareForTransfer(contactDetails as ContactDetails)
            const posting: UserServicesPostData = {
                referralId: props.referralId as string,
                submission: submission as Submission,
                contactDetails: contactDetailsDto
            }

            // console.log('Request submitted: ')
            console.log(posting.submission.responses)
            await AxiosNetworker.postServiceRequest(posting);
            Storage.updateReceiverName(props.receiverName);
            history.push("/success");
        } catch (error) {
            const axiosError = error as AxiosError<any>
            const message = axiosError?.response?.data?.message
            toastr.error(message);
        }
        setLoading(false);
    }

    // endregion
}
