import React, { useState } from "react";
import Rating from "react-rating";
import { RFRatingItemProps } from "./RFRatingItemProps";
import styles from "./RFRatingItem.module.scss";
import * as Icon from "react-bootstrap-icons";
import { Nullable } from "shared/models/misc/types";

export function RFRatingItem(props: RFRatingItemProps) {
    const [value, setValue] = useState<Nullable<number> | undefined>(props.defaultValue);

    return <div className={styles.MainWrap}>

        <div className={styles.Title}>
            {props.model.title}
        </div>

        <div className={styles.Question}>
            {props.model.question}
        </div>

        <div className={styles.Stars}>
            <Rating
                emptySymbol={
                    <span className={styles.Star}>
                        <Icon.Star />
                    </span>
                }
                fullSymbol={
                    <span className={styles.StarFill}>
                        <Icon.StarFill />
                    </span>
                }
                initialRating={!value ? undefined : value}
                onClick={val => {
                    if (val === value) {
                        setValue(undefined);
                        props.onChange(undefined);
                    }
                }}
                onChange={val => {
                    setValue(val);
                    props.onChange(val);
                }}
            />
        </div>
    </div>;
}
