import React from "react";
import styles from "./UIVerifiedBusiness.module.scss";
import checkmark from "../../../assets/checkmark.png";

export function UIVerifiedBusiness() {

    return <div className={styles.MainWrap}>

        <div className={styles.IconWrap}>

            <img
                alt={"Check"}
                src={checkmark}
            />
        </div>

        <div className={styles.ContentWrap}>
            <div className={styles.Title}>
                Verified business
            </div>
        </div>
    </div>
}
