import { ContactDetails } from "../ContactDetails";

export class ContactDetailsFactory {

    public static create(referralId: string)
    : ContactDetails {
        return {
            referralId,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            countryCode: "",
            contactMethods: [],
        } as ContactDetails;
    }
}
