import React from "react";
import { LoadingSpinners } from "../loading-spinners/LoadingSpinners";
import styles from "./PageLoader.module.scss";

export default function PageLoader() {

    return (
        <div className={styles.OverlayWrap}>
            <div className={styles.SpinnersWrap}>
                <LoadingSpinners />
            </div>
        </div>
        );
}
