import React from "react";
import { Button, Modal } from "react-bootstrap";
import cross from "../../assets/cross.png";
import styles from "./ServiceMenuModal.module.scss";
import { ServiceMenuModalProps } from "./ServiceMenuModalProps";

export function ServiceMenuModal(props: ServiceMenuModalProps) {

    const serviceControls = props.services.map((service) =>
        <Button
            key={service.id}
            className={styles.Service}
            onClick={() => {
                props.selectService(service);
                props.onHide();
            }}
        >
            {service.title}
        </Button>
    );

    return <Modal
        dialogClassName="request-quote-modal"
        show={props.show}
        keyboard={false}
        onHide={() => props.onHide()}
    >
        <div
            className={styles.CloseControl}
            onClick={() => props.onHide()}
        >
            <img
                src={cross}
                alt="Cross"
            />
        </div>

        <div className={styles.MainWrap}>

            <div className={styles.Title}>
                How can I help?
            </div>

            <div className={styles.ServicesWrap}>
                {serviceControls}
            </div>

            <div className={styles.Text}>
                Please click on a service from above list.
            </div>
        </div>
    </Modal>
}
