import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { FailedToLoadRequest } from "../../components/shared/failed-to-load-request/FailedToLoadRequest";
import NotFound from "../../components/shared/not-found/NotFound";
import PageLoader from "../../components/shared/page-loader/PageLoader";
import ServiceRequest from "../../components/service-request-view/ServiceRequest";
import { ReviewForm } from "../../components/review-form/ReviewForm";
import { ReferralPageProps } from "./ReferralPageProps";
import { ViewState } from "shared/models/ViewState.enum";
import { AxiosNetworker } from "shared/networking/AxiosNetworker";

export function ReferralPage(props: RouteComponentProps<ReferralPageProps>) {
    const referralId = props.match.params.id;

    const [viewType, setViewType] = useState<ViewState>();
    const [showError, setShowError] = useState<boolean>();
    const [showLoader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {
        loadViewType();
    }, []);

    if (showLoader) {
        return <PageLoader />;
    }

    if (showError) {
        return <FailedToLoadRequest />;
    }

    switch (viewType as any) {
        case ViewState.RequestForm:
            return <ServiceRequest
                referralId={referralId}
            />;
        case ViewState.ReviewForm:
            return <ReviewForm
                referralId={referralId}
            />;
        default:
            return <NotFound/>;
    }

    // region Local Functions

    async function loadViewType()
    : Promise<void> {
        setShowLoader(true);

        try {
            const response = await AxiosNetworker.getViewState(referralId)
            const viewState = response.data
            setViewType(viewState);
        } catch (err) {
            setShowError(true);
        }

        setShowLoader(false);
    }

    // endregion
}
