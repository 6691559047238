import { RFRatingsItemKey, RFRatingsItemModel } from "./RFRatingsItemModel";

export class RFRatingsItemsHelper {

    public static getReviewRatingItems(referrantName: string): RFRatingsItemModel[] {
        return [
            {
                key: RFRatingsItemKey.Communication,
                title: "Professionalism",
                question: "Did you feel your project was taken care of by a true professional?"
            },
            {
                key: RFRatingsItemKey.Budget,
                title: "On Budget",
                question: "Was the project in line with the budget agreed upon?"
            },
            {
                key: RFRatingsItemKey.Attitude,
                title: "Attitude",
                question: `How well would you rate ${referrantName}'s attitude throughout the project?`
            },
            {
                key: RFRatingsItemKey.Recommend,
                title: "Would Recommend",
                question: `Should other clients book ${referrantName}'s services immediately?`
            },
            {
                key: RFRatingsItemKey.Punctuality,
                title: "Punctuality",
                question: `Did ${referrantName} perform their service(s) in a timely manner?`
            },
        ]
    }
}
