
export class Config {

    // // V1 config
    // public static firebaseConfigV1 = {
    //     projectId: "orbitt-9d7f5",
    //     storageBucket: "orbitt-9d7f5.appspot.com",
    //     appId: "1:572083016685:web:137330b79b5a96420b0de4",
    //     measurementId: "G-NEDCL2HCE9"
    // };

    // // V2 config
    // public static firebaseConfigV2 = {
    //     apiKey: "AIzaSyAUxPplPFAbCUCcD_y-jlTDnLD9b708dgY",
    //     authDomain: "orbitt-9d7f5.firebaseapp.com",
    //     projectId: "orbitt-9d7f5",
    //     storageBucket: "orbitt-9d7f5.appspot.com",
    //     messagingSenderId: "572083016685",
    //     appId: "1:572083016685:web:8a81445c1b8888190b0de4",
    //     measurementId: "G-B7E106KLY2"
    // };

    // V3 config
    public static firebaseConfigV3 = {
		apiKey: "AIzaSyDJRuUppM_eFfDydDaRP2ILbn3vzI6cJsQ",
		authDomain: "orbiter-prod-347302.firebaseapp.com",
		projectId: "orbiter-prod-347302",
		storageBucket: "orbiter-prod-347302.appspot.com",
		messagingSenderId: "452530409423",
		appId: "1:452530409423:web:2304c4c19c5a7bf6870cba",
		measurementId: "G-8TRQJXVSZV"
	};

    public static ROOT_URL = process.env.REACT_APP_API_URL;

    // public static headers: AxiosRequestConfig = { 
    //     headers: { 
    //         "Access-Control-Allow-Origin": "*"
    //     }
    // }
}
