import React from "react";
import marker from "../../../assets/marker.png";
import { UILocationProps } from "./UILocationProps";
import styles from "./UILocation.module.scss";

export function UILocation(props: UILocationProps) {

    return <div className={styles.MainWrap}>

        <div className={styles.IconWrap}>

            <img
                alt={"Marker"}
                src={marker}
            />
        </div>

        <div className={styles.ContentWrap}>
            <div className={styles.Title}>
                {props.city}, {props.state}
            </div>

        </div>
    </div>
}
