import React, { useEffect, useState } from "react";
import { FailedToLoadRequest } from "../shared/failed-to-load-request/FailedToLoadRequest";
import { ReferralRequestMapping } from "../../shared/models/mappings/ReferralRequestMapping";
import styles from "./ServiceRequest.module.scss";
import { ServiceForm } from "../service-form/ServiceForm";
import { UserInfo } from "../user-info/UserInfo";
import PageLoader from "../shared/page-loader/PageLoader";
import { ServiceRequestProps } from "./ServiceRequestProps";
import { Service } from "shared/models/Service";
import { UserServicesRequestData } from "shared/models/post+payloads/UserServicesRequestData";
import { AxiosNetworker } from "shared/networking/AxiosNetworker";

export default function ServiceRequest(props: ServiceRequestProps) {
    const referralId = props.referralId;

    const [payload, setPayload] = useState<UserServicesRequestData>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [service, setService] = useState<Service | null>();
    const [loadFailed, setLoadFailed] = useState<boolean>();

    useEffect(() => {
        if (payload?.user) {
            const receiver = payload?.user;
            document.title = `Orbiter | ${receiver?.firstName} ${receiver?.lastName}`;
        }
    }, [payload]);

    useEffect(() => {
        loadRequest();
    }, []);

    if (loadFailed) {
        return <FailedToLoadRequest/>;
    }

    return <div>
        {showLoader ? <PageLoader/> : ""}

        {
            payload?.user &&
                <div className={service ? styles.Hidden : ""}>
                    <UserInfo
                        user={payload?.user}
                        services={payload?.services}
                        selectService={(service) => setService(service)}
                        submitted={payload?.hasSubmitted}
                    />
                </div>
        }

        {
            !!service &&
            <div className={!service ? styles.Hidden : ""}>
               <ServiceForm
                    receiverName={payload?.user.firstName}
                    referralId={referralId}
                    service={service}
                    back={() => setService(null)}
               />
            </div>
        }

    </div>;

    // region Local Functions

    async function loadRequest(): Promise<void> {
        setShowLoader(true);

        try {
            const req = await AxiosNetworker.getServiceRequest(referralId);
            setPayload(req.data);
        } catch (err) {
            console.log(err);
            setLoadFailed(true);
        }

        setShowLoader(false);
    }

    // endregion
}
