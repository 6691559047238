import React from "react";
import checkmark1 from "../../../assets/checkmark1.png";
import styles from "./RFSuccess.module.scss";

export function RFSuccess() {

    return <div className={styles.MainWrap}>

        <div className={styles.Title}>
            Thank you!
        </div>

        <div className={styles.SubTitle}>
            Your review makes our community stronger and more trustworthy.
        </div>

        <div className={styles.ImageBackground}>
            <img
                src={checkmark1}
                className={styles.Image}
                alt="Checkmark"
            />
        </div>
    </div>;
}
