import React, { BaseSyntheticEvent, ChangeEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Dictionary } from "../../../shared/models/Dictionary";
import { SFContactDetailsValidator } from "../../service-form/contact-details/SFContactDetailsValidator";
import { RFVerificationMethod } from "./RFVerificationMethod";
import { RFVerificationProps } from "./RFVerificationProps";
import styles from "./RFVerification.module.scss";
import { VerificationPostData } from "shared/models/post+payloads/VerificationPostData";

export function RFVerification(props: RFVerificationProps) {
    const [verificationMethod, setVerificationMethod] = useState<RFVerificationMethod>(RFVerificationMethod.PhoneNumber);
    const firstSixDigits = `${props.firstSixDigits.slice(0, 3)} - ${props.firstSixDigits.slice(3, 6)} -`
    const {
        register,
        handleSubmit, 
        formState: { errors },
        setValue,
        reset
    } = useForm({mode: "onBlur"});

    return <div className={styles.MainWrap}>

        <div className={styles.Title}>
            First, let&apos;s verify you.
        </div>

        <div className={styles.Subtitle}>
            Before reviewing your service provider, please verify your information so we know it&apos;s you.
        </div>

        {inputMethodOptions()}

        <form
            onSubmit={handleSubmit((values, event) => onSubmit(values, event))}
            className={styles.VerificationDataForm}
        >
            {
                verificationMethod === RFVerificationMethod.PhoneNumber &&

                <Form.Group className={styles.VerificationWrap}>
                    <div className={styles.Data}>
                        <Form.Label className={styles.VerificationLabel}>
                            {firstSixDigits}
                        </Form.Label>

                        <Form.Control
                            {...register("phoneNumber", {
                                required: true,
                                minLength: 4,
                                maxLength: 4,
                            })}
                            className={styles.Input}
                            as="input"
                            type="number"
                            placeholder="Enter last 4 digits"
                            onChange={onPhoneEndingChange}
                        />
                    </div>

                    <div className={styles.Errors}>
                        {!!errors.phoneNumber && <p className="text-danger">Please, enter a valid phone number ending.</p>}
                    </div>
                </Form.Group>
            }

            {
                verificationMethod === RFVerificationMethod.Email &&
                <Form.Group className={styles.VerificationWrap}>
                    <Form.Control
                        {...register("email", {
                            required: true,
                            pattern: SFContactDetailsValidator.emailValidationExpression,
                        })}
                        className={styles.Input}
                        as="input"
                        placeholder="Enter email"
                    />

                    <div className={styles.Errors}>
                        {!!errors.email && <p className="text-danger">Please, enter a valid email.</p>}
                    </div>
                </Form.Group>
            }

            <button
                className={styles.SubmitButton}
                type="submit"
            >
                Next
            </button>
        </form>
    </div>;

    /* ------------------------------- Components ------------------------------- */

    function inputMethodOptions() { 
        return <div className={styles.VerificationMethod}>
            <Form.Check
                className={`${styles.SingleChoice} ${styles.Control}`}
                key={RFVerificationMethod.PhoneNumber}
                type="radio"
                label={"Phone Number"}
                name={"phoneNumber"}
                checked={verificationMethod === RFVerificationMethod.PhoneNumber}
                onChange={(event) => onVerificationMethodChange(event, RFVerificationMethod.PhoneNumber)}
            />

            <Form.Check
                className={`${styles.SingleChoice} ${styles.Control}`}
                key={RFVerificationMethod.Email}
                type="radio"
                label={"Email"}
                name={"email"}
                checked={verificationMethod === RFVerificationMethod.Email}
                onChange={(event) => onVerificationMethodChange(event, RFVerificationMethod.Email)}
            />
        </div>
    }

    /* ----------------------------- Local functions ---------------------------- */

    function onPhoneEndingChange(event: React.ChangeEvent<any>): void {
        const newValue = event.target.value.slice(0, 4)
        setValue("phoneNumber", newValue);
    }

    function onVerificationMethodChange(event: ChangeEvent<HTMLInputElement>, value: RFVerificationMethod) {
        if (!event.target.value) {
            return ;
        }
        reset({phoneNumber: '', email: ''});
        setVerificationMethod(value);
    }

    function onSubmit(formValues: Dictionary<string>, event?: BaseSyntheticEvent): void {
        event?.preventDefault();
        const posting: VerificationPostData = { 
            referralId: ''
        }

        if (verificationMethod === RFVerificationMethod.Email) { 
            let email = formValues['email']
            posting.email = email
        } else if (verificationMethod === RFVerificationMethod.PhoneNumber) { 
            let phone = formValues['phoneNumber']
            posting.phone = phone
        }
        props.submitPosting(posting);
    }

    // endregion
}
