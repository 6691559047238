import React from "react";
import { Form } from "react-bootstrap";
import { RFReferrant } from "../referrant/RFReferrant";
import styles from "./RFMessage.module.scss";
import { RFMessageProps } from "./RFMessageProps";

export function RFMessage(props: RFMessageProps) {

    return <div className={styles.MainWrap}>

        <div
            className={styles.Back}
            onClick={() => props.back()}
        >
            {"< back"}
        </div>

        <div className={styles.Refferant}>
            <RFReferrant
                avatar={props.avatar}
                user={props.user}
            />
        </div>

        <div className={styles.Messages}>

            <div className={styles.Message}>

                <div className={styles.Title}>
                    Write a public review
                </div>

                <div className={styles.Subtitle}>
                    Your review will be public on {props.user.firstName}&apos;s profile
                </div>

                <Form.Control
                    className={styles.Textarea}
                    as="textarea"
                    type="number"
                    placeholder="Share details of your experience with Julio and his services"
                    onChange={e => props.review.message = e.target.value}
                />
            </div>

            <div className={styles.Message}>

                <div className={styles.Title}>
                    Share private feedback
                </div>

                <div className={styles.Subtitle}>
                    Your private feedback will only be sent to the Orbiter team
                </div>

                <Form.Control
                    className={styles.Textarea}
                    as="textarea"
                    type="number"
                    placeholder={`Anything the Orbiter team should know about your experience with ${props.user.firstName} or their services?`}
                />
            </div>
        </div>

        <div className={styles.SubmitWrap}>

            <button
                className={styles.SubmitButton}
                onClick={() => props.submit()}
            >
                Submit
            </button>
        </div>
    </div>;

    // TODO: add support for private feedback in model
}
