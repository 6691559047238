import * as _ from "lodash";
import { Service } from "../Service";
import { Submission } from "../Submission";

export class SubmissionFactory {

    public static create(service: Service, referralId: string): Submission {
        return {
            firstName: '',
            lastName: '',
            serviceTitle: service.title,
            responses: _.cloneDeep(service.questions),
            referralId,
        } as Submission;
    }
}
