import moment from "moment";
import { nanoid } from "nanoid";
import React, { ReactElement, useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import { SFQuestionProps } from "./SFQuestionProps";
import styles from "./SFQuestion.module.scss";
import timesheet from "assets/timesheet.png";
import { QuestionCategory } from "shared/models/Service";

const textMaxLength = 500;

export function SFQuestion(props: SFQuestionProps) {
    const [date, setDate] = useState<string | undefined>();
    const [checkedRadio, setCheckedRadio] = useState<number | null>();
    const {register, setValue, getValues, question, errors} = props;
    const key = props.question.id
    const control = getControl();

    return <Form.Group className={styles.MainWrap}>
        <Form.Label className={styles.Title}>
            {question.prompt} {question.isRequired && " *"}
        </Form.Label>

        {control}

        {errors[key]?.type === "required" && <p className="text-danger">Field is required.</p>}
        {errors[key]?.type === "maxLength" && <p className="text-danger">Text length should be not more then {textMaxLength} symbols.</p>}
    </Form.Group>;

    // region Local functions

    function getControl(): ReactElement | ReactElement[] | undefined {
        let control = register(key, {required: question.isRequired});

        switch (props.question.category) {
            case QuestionCategory.text:
                return <Form.Control
                    {...control}
                    className={`${styles.Input} ${styles.Control}`}
                    type={"input"}
                />;
            case QuestionCategory.paragraph:
                control = register(key, {
                    required: question.isRequired,
                    maxLength: textMaxLength,
                });
                return <Form.Control
                    {...control}
                    as="textarea"
                    className={`${styles.Textarea} ${styles.Control}`}
                />;
            
            case QuestionCategory.datetime:
                return <div className={`${styles.DatePickerWrap} ${styles.Control}`}>
                    <img
                        className={styles.DatePickerIcon}
                        src={timesheet}
                        alt="Timesheet"
                    />
                    <DatePicker
                        className={styles.DatePicker}
                        value={date}
                        onChange={(d) => {
                            props.setValue(key, d, { shouldValidate: true });
                            setDate(formatDate(d as Date));
                        }}
                    />
                </div>;

            case QuestionCategory.singleChoice:
                control = register(key, {required: question.isRequired});
                return question.options?.map((option, index) => <Form.Check
                    className={`${styles.SingleChoice} ${styles.Control}`}
                    key={nanoid()}
                    type="radio"
                    label={option}
                    name={question.prompt}
                    checked={checkedRadio === index}
                    onChange={() => onRadioButtonSelect(index, !props.question.isRequired)}
                    onClick={() => onRadioButtonSelect(index, props.question.isRequired)}
                />);

            case QuestionCategory.multipleChoice:
                control = register(key, {required: question.isRequired});
                return question.options?.map((option, index) => <Form.Check
                    className={`${styles.MultipleChoice} ${styles.Control}`}
                    key={nanoid()}
                    type="checkbox"
                    label={option}
                    name={question.prompt}
                    checked={getValues(key)?.includes(index)}
                    onChange={e => onCheckboxSelect(index, e.target.checked)}
                />)
        }
    }

    function formatDate(date?: Date): string | undefined {
        return date && moment(date).format("MM/DD/yyyy");
    }

    // On select, will read current value using question key and compares to selected value
    // ? Skip is called if they are the same. How if it is dependent on isRequired?
    function onRadioButtonSelect(option: number, skip: boolean): void {
        if (skip) {
            console.log("Skipping ...")
            return;
        }
        const oldValue = getValues(key);
        let newValue = oldValue === option ? null : option;
        console.log('Old: ', oldValue, ' New: ', newValue)
        setCheckedRadio(newValue);
        setValue(key, newValue, {shouldValidate: true});
    }

    function onCheckboxSelect(index: number, isOn: boolean): void {
        const selections = getValues(key);
        const option = question.options[index]
        console.log(option, " isOn?: ", isOn)

        // Turned off
        if (!isOn) {
            // Current value is defined and includes option
            if (selections && selections.includes(index)) { 
                setValue(key, _.without(selections, index), {shouldValidate: true});
            }
        }

        // Turned on
        if (isOn) {
            // Current is defined and does not include option
            if (selections && !selections.includes(index)) { 
                setValue(key, [...selections, index], {shouldValidate: true});
            }
        }
    }
    // endregion
}
