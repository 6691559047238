import parsePhoneNumber from "libphonenumber-js";
import { FieldValues } from "react-hook-form";
import { UseFormGetValues } from "react-hook-form/dist/types/form";

export class SFContactDetailsValidator {

    public static readonly emailValidationExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    public static getPhoneNumberValidationFn(getValues: UseFormGetValues<FieldValues>) {
        return (value: string): Promise<boolean> => {
            return new Promise<boolean>((res) => {
                const phoneNumber = parsePhoneNumber(`+${getValues("countryCode")}${value}`);
                const isValid = !!phoneNumber?.isValid()
                return res(isValid);
            });
        };
    }
}
