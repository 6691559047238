import React from "react";
// import logo from '../../../assets/master-off-black.svg'
// import styles from "./PoweredBy.module.scss";

export function PoweredBy() {
    return <div></div>
    // return <div className={styles.PoweredBy}>
    //     powered by
    //     <img
    //         className={styles.Logo}
    //         src={logo}
    //         alt="Logo"
    //     />
    // </div>;
}
