
import React, { useEffect, useState } from "react";
import { SFGeneralProps } from "./SFGeneralProps";
import styles from "./SFGeneral.module.scss";
import { NetworkHelper } from "shared/networking/NetworkHelper";

export function SFGeneral(props: SFGeneralProps) {

    const [image, setImage] = useState<string>();

    useEffect(() => {
        loadImage()
        // if (!props.service.imagePath) {
        //     return ;
        // }

        // NetworkHelper
        //     .downloadURLFromPath(props.service.imagePath)
        //     .then(imageUrl => {
        //         setImage(imageUrl);
        //         setTimeout(() => props.imageLoaded(), 500);
        //     });
    }, [props.service]);


    return <div className="w-100">
        {
            !!props.service.imagePath &&
            <div
                className={styles.Image}
                style={
                    {background: `url(${image}) center / cover`}
                }
            />
        }

        <div className={styles.GeneralWrap}>
            <div className={styles.General}>
                <div
                    className={styles.Back}
                    onClick={() => props.back()}
                >
                    {"< back"}
                </div>

                <div className={styles.GeneralTitle}>
                    {props.service.title}
                </div>

                <div className={styles.GeneralInfo}>
                    {props.service.description}
                </div>
            </div>
        </div>
    </div>;

    async function loadImage() { 
        try { 
            if (props.service.imagePath) {
                const url = await NetworkHelper.downloadURLFromPath(props.service.imagePath)
                setImage(url);
                setTimeout(() => props.imageLoaded(), 500);
            }
        } catch (err) { 
            console.error(err)
            setImage(undefined)
        }
    }
}
