import { AxiosResponse } from "axios";
import { ReviewPostData } from "shared/models/post+payloads/ReviewPostData";
import { AxiosNetworker } from "shared/networking/AxiosNetworker";
import { RFRatingsItemKey as ItemKey } from "./ratings/RFRatingsItemModel";

export class ReviewFormHelper { 
    public static async handleReviewSubmission(review: ReviewPostData): Promise<AxiosResponse<any>> { 
        let keys: ItemKey[] = [
            ItemKey.Recommend,
            ItemKey.Budget,
            ItemKey.Attitude,
            ItemKey.Communication,
            ItemKey.Punctuality
        ]
        keys.forEach(key => { 
            let rating = review.ratings[key]
            review.ratings[key] = !rating ? null : rating
        })
        console.log(review)
        return await AxiosNetworker.postReview(review as ReviewPostData);
    }
}