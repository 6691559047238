import React from "react";
import { Link } from "react-router-dom";
import { PoweredBy } from "../../components/shared/powered-by/PoweredBy";
import { LocalStorageHelper } from "../../shared/helpers/LocalStorageHelper";
import styles from "./RequestSubmitSuccessPage.module.scss";
import checkmark1 from "../../assets/checkmark1.png";

const defaultReceiverName = "Receiver";

export function RequestSubmitSuccessPage() {
    const receiverLocalStorageName = LocalStorageHelper.getReceiverName();

    const receiverName = receiverLocalStorageName?.length
        ? receiverLocalStorageName
        : defaultReceiverName;

    return <div className={styles.MainWrap}>

        <div>
            <div className={styles.Title}>
                Thank you!
            </div>

            <div className={styles.SubTitle}>
                {receiverName} will reach out shortly.
            </div>

            <div className={styles.ImageBackground}>
                <img
                    src={checkmark1}
                    className={styles.Image}
                    alt="Checkmark"
                />
            </div>
        </div>

        <div className={styles.BottomWrap}>
            <PoweredBy />

            <div className={styles.Disclaimer}>

                <div className={styles.DisclaimerTitle}>
                    We do not sell your data to third-parties.
                </div>

                Read our 
                <a 
                    target="_blank"
                    rel="noreferrer"
                    className={styles.Underlined}
                    href="https://orbiter.co/legal/privacy-policy/"
                >
                    privacy policy
                </a>.
            </div>
        </div>
    </div>;
}

