import React, { useEffect, useState } from "react";
import PageLoader from "components/shared/page-loader/PageLoader";
import { NetworkHelper } from "shared/networking/NetworkHelper";
import styles from "./UIGeneral.module.scss";
import { UIGeneralProps } from "./UIGeneralProps";

export function UIGeneral(props: UIGeneralProps) {
    const [userImage, setUserImage] = useState<string | null>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        loadImage();
    }, [props.user]);

    const user = props.user;

    return <div className={styles.GeneralInfoWrap}>

        { showLoader && <PageLoader /> }

        <img
            onLoad={() => imageLoaded()}
            onError={() => imageLoaded()}
            className={styles.AvatarContainer}
            src={userImage as string}
            alt="Avatar"
        />

        <div className={styles.Name}>
            {user.firstName} {user.lastName}
        </div>

        {
            user.company.title &&
            <div className={styles.Title}>
                {user.company.title}
            </div>
        }

        <div className={styles.CompanyName}>
            {user.company.name}
        </div>


        {/*TODO: Implement recommendations*/}
        {/*{*/}
        {/*    !!user.stats.recommendations &&*/}
        {/*    <div className={styles.Recommendations}>*/}
        {/*        <UserInfoRecommendations user={user}/>*/}
        {/*    </div>*/}
        {/*}*/}

    </div>

    // region Local Function

    async function loadImage(): Promise<void> {
        setShowLoader(true);
        try {
            const imageUrl = await NetworkHelper.downloadURLFromID(user.id)
            setUserImage(imageUrl);
        } catch (error) {
            console.log(error);
        }
    }

    function imageLoaded(): void {
        setShowLoader(false);
    }

    // endregion
}
