import React from "react";
import styles from "./FailedToLoadRequest.module.scss";
import logo from '../../../assets/master-off-black.svg'

export function FailedToLoadRequest() {

    return <div className={styles.MainContainer}>
        <div className={styles.MainWrap}>

            <img
                className={styles.Image}
                src={logo}
                alt="Orbiter"
            />

            <div className={styles.Title}>
                Orbiter facilitates warm introductions to help business grow organically.
            </div>

            <div className={styles.Text}>
                Unfortunately this introduction has already been made!
            </div>

            <div className={styles.Text}>
                Please contact the person who sent you this link so they can send you an updated introduction link.
            </div>
        </div>
    </div>
}
