import axios, { AxiosError, AxiosResponse } from "axios";
import { Config } from "shared/helpers/Config";
import { ReviewPostData } from "shared/models/post+payloads/ReviewPostData";
import { ReviewRequestData } from "shared/models/post+payloads/ReviewRequestData";
import { UserServicesPostData } from "shared/models/post+payloads/UserServicesPostData";
import { UserServicesRequestData } from "shared/models/post+payloads/UserServicesRequestData";
import { VerificationPostData } from "shared/models/post+payloads/VerificationPostData";
import { VerificationRequestData } from "shared/models/post+payloads/VerificationRequestData";
import { ViewState } from "shared/models/ViewState.enum";

export class AxiosNetworker { 
    private static REQUEST_URL = `${Config.ROOT_URL}/referral-requests`

    public static getViewState(referralId: string): Promise<AxiosResponse<ViewState>> { 
        const url = `${this.REQUEST_URL}/${referralId}/view`
        return axios.get(url);
    } 

    public static getServiceRequest(referralId: string): Promise<AxiosResponse<UserServicesRequestData>> { 
        return axios.get(`${this.REQUEST_URL}/${referralId}`);
    }

    public static postServiceRequest(posting: UserServicesPostData): Promise<AxiosResponse<void, AxiosError>> | void { 
        // console.log(`[postServiceRequest] ReferralId ${posting.referralId}`)
        // console.log(`[postServiceRequest] Submission referral id: ${posting.submission.referralId}`)
        // console.log(`[postServiceRequest] Submission first name: ${posting.submission.firstName}`)
        // console.log(`[postServiceRequest] Submission last name: ${posting.submission.lastName}`)
        // console.log(`[postServiceRequest] Submission title: ${posting.submission.serviceTitle}`)
        // console.log(`[postServiceRequest] Submission responses: ${posting.submission.responses}`)

        // console.log(`[postServiceRequest] Contact referral id: ${posting.contactDetails.referralId}`)
        // console.log(`[postServiceRequest] Contact first name: ${posting.contactDetails.firstName}`)
        // console.log(`[postServiceRequest] Contact last name: ${posting.contactDetails.lastName}`)
        // console.log(`[postServiceRequest] Contact email: ${posting.contactDetails.email}`)
        // console.log(`[postServiceRequest] Contact country code: ${posting.contactDetails.countryCode}`)
        // console.log(`[postServiceRequest] Contact phone: ${posting.contactDetails.phoneNumber}`)
        // console.log(`[postServiceRequest] Contact methods: ${posting.contactDetails.contactMethods}`)
        return axios.post(`${this.REQUEST_URL}/${posting.referralId}`, posting);
    }

    public static getReviewState(referralId: string): Promise<AxiosResponse<VerificationRequestData>> {
        return axios.get(`${this.REQUEST_URL}/${referralId}/review/verification-data`);
    }

    public static postPreverification(posting: VerificationPostData): Promise<AxiosResponse<ReviewRequestData>> {
        return axios.post(`${this.REQUEST_URL}/${posting.referralId}/review/verification-data`, posting);
    }

    public static postReview(posting: ReviewPostData): Promise<AxiosResponse> {
        return axios.post(`${this.REQUEST_URL}/${posting.referralId}/review`, posting);
    }

    
}