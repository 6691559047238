import React, { useEffect, useState } from "react";
import { RFReferrantProps } from "./RFReferrantProps";
import styles from "./RFReferrant.module.scss";
import PageLoader from "components/shared/page-loader/PageLoader";

export function RFReferrant(props: RFReferrantProps) {

    const [showLoader, setShowLoader] = useState<boolean>();

    useEffect(() => {
        setShowLoader(true);
        setTimeout(() => setShowLoader(false), 1000);
    }, []);

    return <div className={styles.MainWrap}>
        {showLoader && <PageLoader />}

        <img
            className={styles.Image}
            onLoad={() => setShowLoader(false)}
            onError={() => setShowLoader(false)}
            src={props.avatar}
            alt="Avatar"
        />

        <div className={styles.Info}>
            <div className={styles.Name}>
                {fullName()}
            </div>

            <div className={styles.Title}>
                {props.user.company.title}
            </div>
            
            <div className={styles.Company}>
                {props.user.company.name}
            </div>
        </div>
    </div>;

    function fullName() { 
        return `${props.user.firstName} ${props.user.lastName}`
    }
}
