import React, { forwardRef, useImperativeHandle, useRef } from "react";
import * as _ from "lodash";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Dictionary } from "shared/models/Dictionary";
import { ContactMethod } from "shared/models/ContactDetails";
import { SFContactDetailsValidator } from "./SFContactDetailsValidator";
import { SFContactDetailsApi } from "./SFContactDetailsApi";
import { SFContactDetailsProps } from "./SFContactDetailsProps";
import styles from "./SFContactDetails.module.scss";

const SFContactDetails = forwardRef((props: SFContactDetailsProps, ref) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues
    } = useForm({mode: "onChange"});

    const onSubmit = (data: Dictionary<string>) => {
        Object.keys(data).forEach((key) => {
            if (_.has(props.contactDetails, key)) {
                (props.contactDetails as any)[key] = data[key];
            }

            if (_.has(props.submission, key)) {
                (props.submission as any)[key] = data[key];
            }
        });
    };
    const submitButtonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => ({
            validate: () => {
                triggerValidation();
                return isValid;
            }
        } as SFContactDetailsApi),
    );

    register("contactMethods", {required: true});
    register("phoneNumber", {validate: SFContactDetailsValidator.getPhoneNumberValidationFn(getValues)});
    register("countryCode", {required: true});

    // @ts-ignore
    return <div className={styles.MainWrap}>

        <form onSubmit={handleSubmit(onSubmit, )}>
            {firstName()}

            <Form.Group>
                <Form.Label className={styles.Label}>
                    Last name *
                </Form.Label>

                <Form.Control
                    {...register("lastName", { required: true })}
                    className={styles.Input}
                    as="input"
                />

                {errors.lastName && <p className="text-danger">Last name is required.</p>}

            </Form.Group>

            <Form.Group className="mb-0">
                <Form.Label className={styles.Label}>
                    Your phone number *
                </Form.Label>

                <PhoneInput
                    onChange={onPhoneNumberChange}
                    containerClass={styles.PhoneInputContainer}
                    inputClass={styles.PhoneInput}
                    buttonClass={styles.FlagSelector}
                    country={"us"}
                    preferredCountries={["us", "mx", "ca"]}
                    preserveOrder={["preferredCountries"]}
                />

                {errors.phoneNumber && <p className="text-danger">Please, enter a valid phone number.</p>}
            </Form.Group>

            <Form.Group>
                <Form.Label className={styles.Label}>
                    Your email *
                </Form.Label>

                <Form.Control
                    {...register("email", {
                        required: true,
                        pattern: SFContactDetailsValidator.emailValidationExpression,
                    })}
                    className={styles.Input}
                    as="input"
                    type="email"
                />

                {errors.email?.type === "required" && <p className="text-danger">Email is required.</p>}
                {errors.email?.type === "pattern" && <p className="text-danger">Please, enter a valid email.</p>}
            </Form.Group>

            <Form.Group>
                <Form.Label className={`${styles.Label} ${styles.ContactLabel}`}>
                    How do you prefer to be <p>contacted? *</p>
                </Form.Label>

                <Form.Check
                    className={styles.Check}
                    type="checkbox"
                    label="Phone call"
                    onChange={e => toggleContactMethod(ContactMethod.Call, e.target.checked)}
                />

                <Form.Check
                    className={styles.Check}
                    type="checkbox"
                    label="Text"
                    onChange={e => toggleContactMethod(ContactMethod.Text, e.target.checked)}
                />

                <Form.Check
                    className={styles.Check}
                    type="checkbox"
                    label="Email"
                    onChange={e => toggleContactMethod(ContactMethod.Email, e.target.checked)}
                />

                {errors.contactMethods?.type === "required" && <p className="text-danger">Field is required.</p>}
            </Form.Group>

            <button
                className={styles.Hidden}
                type="submit"
                ref={submitButtonRef}
            >
                Submit
            </button>

        </form>
    </div>;

    /* ------------------------------- Components ------------------------------- */

    function firstName() { 
        return <Form.Group>
            <Form.Label className={styles.Label}>
                First name *
            </Form.Label>

            <Form.Control
                {...register("firstName", { required: true })}
                className={styles.Input}
                as="input"
            />
            {errors.firstName && <p className="text-danger">First name is required.</p>}
        </Form.Group>
    }

    /* -------------------------------- Functions ------------------------------- */

    function onPhoneNumberChange(value: string, data: CountryData): void {
        setValue("phoneNumber", value.replace(data.dialCode, ""), {shouldValidate: true});
        setValue("countryCode", data.dialCode, {shouldValidate: true});
    }

    function toggleContactMethod(method: ContactMethod, value: boolean): void {
        const contactMethods = props.contactDetails.contactMethods;

        if (value && !contactMethods.includes(method)) {
            contactMethods.push(method);
            setValue("contactMethods", props.contactDetails.contactMethods, {shouldValidate: true});
        }

        if (!value && contactMethods.includes(method)) {
            props.contactDetails.contactMethods = _.without(contactMethods, method);
            setValue("contactMethods", props.contactDetails.contactMethods, {shouldValidate: true});
        }

        return ;
    }

    function triggerValidation(): void {
        submitButtonRef?.current?.click();
    }

    // endregion
});

SFContactDetails.displayName = "ServiceFormClientInfo";
export default SFContactDetails;


