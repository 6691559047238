import React from "react";
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import { ReferralPage } from "../pages/referral/ReferralPage";
import NotFound from "./shared/not-found/NotFound";
import { RequestSubmitSuccessPage } from "../pages/request-submit-success/RequestSubmitSuccessPage";
import { TermsAndConditionsPage } from "../pages/terms-and-conditions/TermsAndConditionsPage";

function App() {
    return (
        <BrowserRouter>
            <Switch>

                <Redirect exact from="/" to="not-found"/>

                <Route
                    exact={false}
                    path={"/not-found"}
                    component={NotFound}
                >
                </Route>

                <Route
                    exact
                    path={"/terms-and-conditions"}
                    component={TermsAndConditionsPage}
                />

                <Route
                    exact={false}
                    path={"/success"}
                    component={RequestSubmitSuccessPage}
                >
                </Route>

                <Route
                    exact
                    path={"/:id"}
                    component={ReferralPage}
                >
                </Route>

                <Route
                    component={NotFound}
                />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
