import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import { FailedToLoadRequest } from "../shared/failed-to-load-request/FailedToLoadRequest";
import { PoweredBy } from "../shared/powered-by/PoweredBy";
import { RFMessage } from "./message/RFMessage";
import { RFRatings } from "./ratings/RFRatings";
import { RFSuccess } from "./success/RFSuccess";
import PageLoader from "../shared/page-loader/PageLoader";
import { RFVerification } from "./verification/RFVerification";
import { ReviewFormProps } from "./ReviewFormProps";
import { ReviewFormSteps } from "./ReviewFormSteps";
import styles from "./ReviewForm.module.scss";
import { ReviewPostData } from "shared/models/post+payloads/ReviewPostData";
import { VerificationPostData } from "shared/models/post+payloads/VerificationPostData";
import { ReviewRequestData } from "shared/models/post+payloads/ReviewRequestData";
import { NetworkHelper } from "shared/networking/NetworkHelper";
import { ReviewFormHelper } from "./ReviewFormHelper";
import { VerificationRequestData } from "shared/models/post+payloads/VerificationRequestData";
import { AxiosNetworker } from "shared/networking/AxiosNetworker";

export function ReviewForm(props: ReviewFormProps) {
    const referralId = props.referralId;
    const history = useHistory();

    const [avatar, setAvatar] = useState<string>();
    const [showLoader, setShowLoader] = useState<boolean>();
    const [pvPayload, setPvPayload] = useState<VerificationRequestData>();
    const [payload, setPayload] = useState<ReviewRequestData>();
    const [review, setReview] = useState<ReviewPostData>();
    const [loadFailed, setLoadFailed] = useState<boolean>();
    const [step, setStep] = useState<ReviewFormSteps>();

    useEffect(() => {
        setStep(ReviewFormSteps.Ratings);
        checkIfReviewAvailable(); // for phone Number verifying
    }, []);

    if (loadFailed) {
        return <FailedToLoadRequest/>;
    }

    let content;

    switch(step) {
        case ReviewFormSteps.Verification:
            content = pvPayload && avatar && <RFVerification
                avatar={avatar}
                user={pvPayload.user}
                firstSixDigits={pvPayload.firstSixDigits}
                submitPosting={posting => postPreverification(posting)}
            />;
            break;
        case ReviewFormSteps.Ratings:
            content = payload && avatar && review && <RFRatings
                avatar={avatar}
                review={review}
                user={payload.user}
                submitted={() => setStep(ReviewFormSteps.Message)}
            />;
            break;
        case ReviewFormSteps.Message:
            content = payload && avatar && review && <RFMessage
               avatar={avatar}
               review={review}
               user={payload.user}
               back={() => setStep(ReviewFormSteps.Ratings)}
               submit={() => submitReview()}
            />;
            break;
        case ReviewFormSteps.Done:
            content = <RFSuccess/>;
    }

    return <div className={styles.MainContainer}>
        {showLoader && <PageLoader/>}

        <div className={styles.MainWrap}>
            <div>
                {content}
            </div>
            <PoweredBy/>
        </div>
    </div>;

    // region Local functions

    async function checkIfReviewAvailable(): Promise<void> {
        setShowLoader(true);
        try {
            const request = await AxiosNetworker.getReviewState(referralId);
            const payload = request.data as VerificationRequestData
            setPvPayload(payload)
            loadImage(payload)
            setStep(ReviewFormSteps.Verification);
        } catch (err) {
            const code = (err as AxiosError).code
            if (code === "406") {
                history.push("/not-found");
            }
            setLoadFailed(true);
        }
        setShowLoader(false);
    }

    async function postPreverification(posting: VerificationPostData): Promise<void> { 
        setShowLoader(true)
        try { 
            posting.referralId = referralId
            const request = await AxiosNetworker.postPreverification(posting)
            const payload = request.data as ReviewRequestData
            const review: ReviewPostData = {
                referralId: payload.referralId,
                message: '',
                ratings: { }
            }

            setPayload(payload);
            loadImage(payload);
            setStep(ReviewFormSteps.Ratings);
            setReview(review)
        } catch (error) { 
            console.log(error)
            toastr.error((error as AxiosError<any>)?.response?.data?.message);
        }
        setShowLoader(false)
    }

    async function loadImage(payload: ReviewRequestData): Promise<void> {
        setShowLoader(true);
        try {
            let image = await NetworkHelper.downloadURLFromID(payload.user.id)
            setAvatar(image);
        } catch (e) {
            console.log(e);
        }
        setShowLoader(false);
    }

    async function submitReview(): Promise<void> {
        if (!review) { 
            return
        }

        setShowLoader(true);
        try {
            await ReviewFormHelper.handleReviewSubmission(review)
            setStep(ReviewFormSteps.Done);
        } catch (error) {
            toastr.error((error as AxiosError<any>)?.response?.data?.message);
        }
        setShowLoader(false);
    }

    // endregion
}
