export interface ContactDetails {
    referralId: string
    firstName: string
    lastName: string
    email: string
    countryCode: string
    phoneNumber: string
    contactMethods: ContactMethod[];
    createdOn?: Date
}

export enum ContactMethod {
    Call = "CALL",
    Text = "TEXT",
    Email = "EMAIL"
}
