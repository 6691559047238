import PerfectScrollbar from "react-perfect-scrollbar";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import styles from "./UIGallery.module.scss";
import { UIGalleryProps } from "./UIGalleryProps";
import PageLoader from "components/shared/page-loader/PageLoader";
import { NetworkHelper } from "shared/networking/NetworkHelper";

export function UIGallery(props: UIGalleryProps) {
    const [galleryItems, setGalleryItems] = useState<readonly string[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        loadImages();
    }, []);

    const singleImageClass = props.galleryPaths.length === 1
        ? styles.SingleImage
        : "";

    const galleryPhotos = galleryItems.map(galleryItem => <img
        onLoad={() => setShowLoader(false)}
        onError={() => setShowLoader(false)}
        className={`${styles.Image} ${singleImageClass}`}
        src={galleryItem}
        key={nanoid()}
        alt={"Gallery"}
    />);

    return showLoader
        ? <PageLoader />
        : <PerfectScrollbar>

        <div className={styles.MainWrap}>
                {galleryPhotos}
        </div>
    </PerfectScrollbar>;

    // region Local functions

    async function loadImages(): Promise<void> {
        setShowLoader(true);
        try {
            const galleryUrls = props.galleryPaths.map(async (path) => (
                    NetworkHelper.downloadURLFromPath(path)
                )
            );
            const galleryItems = await Promise.all(galleryUrls);
            setGalleryItems(galleryItems);

        } catch (error) {
            setGalleryItems([]);
            console.log(error);
        }
        if (!galleryItems?.length) {
            setShowLoader(false);
        }
    }

    // endregion
}
