import { nanoid } from "nanoid";
import React from "react";
import { UIServicesProps } from "./UIServicesProps";
import styles from "./UIServices.module.scss";
import checkmark from "../../../assets/checkmark.png";

export function UIServices(props: UIServicesProps) {

    const services = props.services.map(service =>
        <div
            key={nanoid()}
            className={styles.Service}
        >
            <div className={styles.Icon}>
                <img
                    src={checkmark}
                    alt={"Check"}
                />
            </div>

            <div className={styles.Text}>
                {service.title}
            </div>
    </div>);

    return <div className={styles.MainWrap}>
        <div className={styles.Title}>
            Services
        </div>

        {services}
    </div>;
}
