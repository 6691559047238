import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ServiceMenuModal } from "../service-menu-modal/ServiceMenuModal";
import { UICompany } from "./company/UICompany";
import { UIGeneral } from "./general/UIGeneral";
import { UserInfoProps } from "./UserInfoProps";
import styles from "./UserInfo.module.scss";

export function UserInfo(props: UserInfoProps) {

    const [showRequestQuote, setShowRequestQuote] = useState<boolean>(false);

    const onRequestQuote = () => setShowRequestQuote(true);
    const onRequestQuoteHide = () => setShowRequestQuote(false);

    return <div className={styles.MainContainer}>

        <div className={styles.MainWrap}>

            <UIGeneral
                user={props.user}
            />

            <UICompany
                user={props.user}
                services={props.services}
            />

            {
                !props.submitted &&
                <div className={styles.RequestQuoteContainer}>

                    <Button
                        onClick={onRequestQuote}
                        className={styles.RequestQuote}
                    >
                        Request a Quote
                    </Button>
                </div>
            }

            <ServiceMenuModal
                show={showRequestQuote}
                services={props.services}
                onHide={onRequestQuoteHide}
                selectService={(service) => props.selectService(service)}
            />
        </div>
    </div>;
}
