import { ContactDetails } from "../ContactDetails";

export class ContactDetailsMapping { 
    public static prepareForTransfer(contact: ContactDetails): ContactDetails { 
        if (contact.countryCode[0] !== '+') { 
            let newCode = '+' + contact.countryCode
            contact.countryCode = newCode
        }
        return contact
    }
}