import React from "react";
import { RFRatingItem } from "../rating-item/RFRatingItem";
import { RFReferrant } from "../referrant/RFReferrant";
import { RFRatingsItemsHelper } from "./RFRatingsItemsHelper";
import { RFRatingsItemKey } from "./RFRatingsItemModel";
import { RFRatingsProps } from "./RFRatingsProps";
import styles from "./RFRatings.module.scss";

export function RFRatings(props: RFRatingsProps) {
    const items = RFRatingsItemsHelper.getReviewRatingItems(props.user.firstName);

    const ratingItems = items.map(item => 
        <RFRatingItem
            defaultValue={props.review.ratings[item.key]}
            key={item.key}
            model={item}
            onChange={value => onRatingChange(item.key, value)}
        />
    );

    return <div className={styles.MainWrap}>

        <div className={styles.Referrant}>
            <RFReferrant
                avatar={props.avatar}
                user={props.user}
            />
        </div>

        <div className={styles.Reviews}>
            {ratingItems}
        </div>

        <div className={styles.NextWrap}>
            <button
                className={styles.SubmitButton}
                onClick={() => props.submitted()}
            >
                Next
            </button>
        </div>
    </div>;

    // region Local Functions

    function onRatingChange(key: RFRatingsItemKey, value?: number): void {
        props.review.ratings[key] = value ? value : null;
    }

    // endregion
}

